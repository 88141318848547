<template>
	<TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="desc"
        :filters="filters"
        addButtonName="Add Asset Category">

        <template v-slot:form_items="{formdata}">
            <el-form-item label="Code" prop="code">
                <el-input v-model="formdata.code" maxlength="100" minlength="3" />
            </el-form-item>
            <el-form-item label="Description" prop="description">
                <el-input v-model="formdata.description" />
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'dict_assetcategory',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
        	module: 'dictionaryAssetCategory',
            form_initial: {},
            formRules: {
            	code: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field : 'code',          
                    title : 'Code',          
                    width : 100,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field    : 'description',   
                    title    : 'Description',   
                    minWidth : 200,
                    params   : {
                        filter : 'input', 
                    },
                },
            ],
            filters: {
                code        : { value: undefined, strict: false, },
                description : { value: undefined, strict: false, },
            }
        }
    },

    computed: {
    	...mapState({
    		user: state => state.app.user,
    	}),

    	rights(){
    		return {
				view : (this.user && this.user.rights) ? this.user.rights['get-portf-settings']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-portf-settings']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-portf-settings']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-portf-settings']: false,
    		}
    	}
    },
}
</script>
